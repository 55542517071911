import CallToAction from "components/CallToAction"
import Image from "next/image"
import currencyFormatter from "utilities/currencyFormatter"

export default function ArticleCard({ card, cardSource }) {
  switch (cardSource) {
    case "case-wins":
      return <BasicArticleCard card={card} />
    case "in-your-community":
      return <ImageArticleCard card={card} />
    default:
      return "Invalid card type"
  }
}

function ImageArticleCard({ card }) {
  return (
    <div className="flex flex-col min-w-[225px]">
      <div className="min-h-[150px] relative">
        <Image
          src={card.content.image?.filename ? card.content.image?.filename : "/assets/placeholder.png"}
          alt={card.content.image?.alt ? card.content.image.alt : "Husband and Wife Law Team"}
          placeholder={card.content.image?.blurDataURL ? "blur" : "empty"}
          blurDataURL={card.content.image?.blurDataURL}
          fill
          style={{ objectFit: "cover", width: "100%" }}
        />
      </div>
      <div className="h-full shadow-4 flex flex-col items-center justify-center p-4 bg-white">
        {card.content.name ? <h4 className="text-center pb-1">{card.content.name}</h4> : null}

        <CallToAction href={card.full_slug} style="secondary-black" className="text-center">
          Read Story
        </CallToAction>
      </div>
    </div>
  )
}

function BasicArticleCard({ card }) {
  return (
    <div className="bg-white min-w-[225px] h-[200px] p-6 shadow-4 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center">
        <h3 className="text-center pb-2 whitespace-nowrap">{currencyFormatter(card?.content?.amount)}</h3>
        {card.content.practice_area ? <h4 className="text-center pb-1">{card?.content?.practice_area.name}</h4> : null}

        <CallToAction href={card.full_slug} style="secondary-black" className="text-center">
          Read Story
        </CallToAction>
      </div>
    </div>
  )
}
