import richText from "utilities/richText"
import cn from "classnames"
import CardCarousel from "components/CardCarousel"

export default function CardAndContent({ blok }) {
  return (
    <section className={`bg-${blok.background} bg-cover px-5 py-8 overflow-x-hidden`}>
      <div className="mx-auto max-w-screen-xl">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-8">
          <div
            className={cn("lg:basis-1/3 lg:flex lg:flex-col lg:justify-center text-white", {
              "text-primary": blok.background === "white " || blok.background === "highlight-tint",
            })}
          >
            <h3>{blok.heading}</h3>
            <div className="prose-a:text-left">{richText(blok.content)}</div>
          </div>
          <div className="relative">
            <CardCarousel cards={blok.cards} cardNumber={blok.card_number} cardSource={blok.card_source} />
          </div>
        </div>
      </div>
    </section>
  )
}
