import { useState, useCallback } from "react"
import Arrow from "public/assets/right-arrow.svg"
import useWindowSize from "utilities/useWindowSize"
import ArticleCard from "components/ArticleCard"

export default function CardCarousel({ cards, cardNumber, cardSource }) {
  const { width } = useWindowSize()

  const visibleSlides = useCallback(() => {
    if (width >= 1024 && cards?.length >= 4) return cardNumber
    if (width >= 900 && cards?.length >= 3) return 3
    if (width >= 640 && cards?.length >= 2) return 2
    return 1
  }, [cards?.length, width, cardNumber])

  const [currentSlide, setSlide] = useState(0)

  const slideWidth = 225
  // matches the gap value between slides
  const gapOffset = 16

  const carouselWidth =
    width >= 900
      ? slideWidth * visibleSlides() + gapOffset * (cardNumber - 1)
      : width >= 640
        ? slideWidth * visibleSlides() + gapOffset
        : slideWidth * visibleSlides()

  function moveSlidesLeft() {
    currentSlide !== 0
      ? setSlide(currentSlide - (slideWidth + gapOffset))
      : setSlide(cards?.length * (slideWidth + gapOffset) - visibleSlides() * (slideWidth + gapOffset))
  }

  function moveSlidesRight() {
    currentSlide <= (cards?.length - 1 - visibleSlides()) * (slideWidth + gapOffset)
      ? setSlide(currentSlide + slideWidth + gapOffset)
      : setSlide(0)
  }

  return (
    <div className="mx-auto items-center flex flex-col max-w-[960px] justify-center pt-6 relative">
      <div className="overflow-x-hidden overflow-y-hidden mx-auto relative pb-2">
        <div
          className="flex gap-4 items-stretch transition-all duration-700"
          style={{
            transform: `translateX(-${currentSlide}px)`,
            width: `${carouselWidth}px`,
          }}
          aria-live="off"
        >
          {cards?.map((card, idx) => {
            return <ArticleCard card={card} key={idx} cardSource={cardSource} />
          })}
        </div>
        {visibleSlides() < cards?.length ? (
          <>
            <button
              onClick={moveSlidesLeft}
              className="absolute left-[-1px] bottom-[7.5px]"
              aria-label="Previous Slide"
            >
              <Arrow className="carousel-arrow rotate-180 text-secondary hover:text-tertiary" />
            </button>
            <button onClick={moveSlidesRight} className="absolute right-[-1px] bottom-[7.5px]" aria-label="Next Slide">
              <Arrow className="carousel-arrow text-secondary hover:text-tertiary" />
            </button>
          </>
        ) : null}
      </div>
    </div>
  )
}
